.wrapper {
  margin-top: calc(var(--header) + var(--line) + 64px);
}

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}

.title {
  margin-bottom: 20px;
  font-weight: 600;
}

.text {
  color: var(--gray-600);
  margin-bottom: 20px;
}

.errorText {
  color: var(--gray-600);
  font-weight: 400;
  line-height: 30px;
}

.red {
  color: var(--error-500);
}

.texts {
  flex: 1 100%;
  margin-right: 72px;
}

.errorTexts {
  flex: 1 100%;
}

.qrWrapper {
  padding: 12px;
  border-radius: 8px;
  width: 150px;
  height: 200px;
  text-align: center;
  border: 1px solid var(--gray-200);
}

.visit {
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  margin-bottom: 3px;
}

.qr {
  margin-bottom: 5px;
}

.subtitle {
  margin-bottom: 64px;
  font-weight: 600;
}

.subtitle2 {
  margin-bottom: 32px;
  font-weight: 600;
}

.subtitle3 {
  margin-bottom: 20px;
  font-weight: 600;
}

.fill {
  color: var(--gray-500);
  margin-bottom: 32px;
}

.grey {
  background: var(--gray-50);
  padding: 48px 0 0;
}

.section {
  padding: 48px 0;
  border-bottom: 1px solid var(--gray-200);
}

.section2 {
  padding: 48px 0;
}

.info:not(:last-child) {
  border-bottom: 1px solid var(--gray-200);
}

.spinner {
  position: fixed;
  top: calc(var(--header) + var(--line));
  width: 100vw;
  left: 0;
  height: calc(100vh - var(--header) - var(--line));
}

.field {
  display: flex;
  flex-direction: column;
}

.field p {
  font-weight: 600;
  margin-bottom: 8px;
}

.green {
  color: var(--teal-500);
  margin-bottom: 0;
}

.placeholder {
  border-radius: 8px;
  border: 1px solid var(--warning-300);
  background: var(--warning-25);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  margin-bottom: 20px;
}

.placeholderTexts {
  color: var(--warning-700);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.placeholderIcon {
  color: var(--warning-600);
  margin-right: 12px;
  font-size: 24px !important;
}

.copy {
  margin-left: 5px;
}

.copyIcon {
  display: inline-block;
  margin-left: 5px;
}

.tip {
  margin-left: 5px;
  display: inline;
}

.copyWrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkText {
  color: var(--teal-400);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.mrb {
  margin-left: 10px;
}

.info:not(:last-child) {
  border-bottom: 1px solid var(--gray-200);
}

.info {
  background: none !important;
}
