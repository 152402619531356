.wrapper {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.delete {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--animation);
}

.wrapper:hover .delete {
  opacity: 1;
  pointer-events: all;
}

.deleteIcon {
  cursor: pointer;
  color: var(--red-500);
}

.placeholder {
  background: var(--teal-500);
  color: var(--snow);
  font-family: var(--font);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.wrapper.big {
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
}

.wrapper.big .placeholder {
  font-size: 48px;
}

.img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
