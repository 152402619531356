.wrapper {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);
  position: relative;
}

.divider:after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 45%;
  height: 1px;
  content: "";
  display: inline-block;
  background: var(--gray-200);
}

.divider:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 45%;
  height: 1px;
  content: "";
  display: inline-block;
  background: var(--gray-200);
}

.side {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.side.rightSide {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding: 48px 80px 48px 452px;
}

.rightSideContent {
  max-width: 850px;
}

.side.leftSide {
  background-size: cover;
  position: fixed;
  width: 360px;
  background: var(--cool-600);
}

.spinner {
  left: 360px;
  top: 0;
  width: calc(100% - 360px) !important;
}

.verifyText {
  color: var(--gray-600);
}

.logo {
  width: 210px;
  position: absolute;
  top: 48px;
  left: 48px;
}

.b {
  position: absolute;
  left: -20px;
  bottom: 0;
  z-index: 0;
}

.year {
  color: var(--gray-300);
  position: absolute;
  z-index: 1;
  bottom: 32px;
  left: 32px;
}

.wizard {
  list-style: none;
  margin: 160px 0 0 0;
  padding: 0 48px;
}

.step {
  display: flex;
  flex-direction: row;
  font-family: var(--font);
  font-weight: 600;
  font-size: 16px;
  position: relative;
  line-height: 24px;
  color: var(--snow);
  align-items: center;
}

.step:not(:last-child) {
  margin-bottom: 54px;
}

.stepPoint {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  transition: border-width var(--animation), background var(--animation);
  will-change: border-width, background;
  background: var(--cool-600);
  margin-right: 16px;
  border: 2px solid var(--snow);
}

.step.passedStep .stepPoint {
  background: var(--teal-400);
  border-width: 0;
}

.step.passedStep {
  color: var(--teal-400);
}

.step .icon {
  width: 16px;
  height: 14px;
}

.step.passedStep .icon {
  color: var(--cool-600);
  font-size: 16px;
}

.step.activeStep .stepPoint {
  border-width: 11px;
}

.step.activeStep .stepPoint .point {
  display: none;
}

.point {
  width: 10px;
  height: 10px;
  background: var(--snow);
  border-radius: 50%;
}

.step:not(:last-child):after {
  width: 2px;
  transition: background var(--animation);
  will-change: background;
  height: 40px;
  background: var(--gray-600);
  display: inline-block;
  content: "";
  position: absolute;
  left: 16px;
  top: 100%;
  border-radius: 2px;
  margin-top: 7px;
}

.step.passedStep:after {
  background: var(--teal-400);
}

.title {
  margin-bottom: 20px;
  font-weight: 600;
}

.subtitle {
  margin-bottom: 32px;
}

.verifySubtitle {
  color: var(--gray-500);
  margin-bottom: 24px;
}

.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray-200);
}

.fullInput {
  width: 100%;
}

.field.last {
  border-bottom: none;
  margin-bottom: 0;
}

.field .input {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.radio {
  flex-direction: column;
  margin-top: 14px;
}

.footer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer button {
  margin-bottom: 30px;
}

.green {
  margin-top: 12px;
  color: var(--teal-400);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.greenText {
  color: var(--teal-400);
  margin-left: 4px;
  display: inline-block;
}

.help {
  width: 340px;
}

.passwordFooter {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

.passwordFooter a {
  margin-right: 12px;
}

.stepTwo {
  width: 420px;
}

.smallHeader {
  display: none;
  padding: 32px 16px;
}

.pageFooter {
  color: var(--gray-600);
  display: none;
  padding: 32px 0 32px;
}

.mobilePasswordCheck {
  display: none;
}

@media all and (max-width: 767px) {
  .verifySubtitle {
    font-size: 20px !important;
  }
  .mobilePasswordCheck {
    display: block;
  }
  .field .input {
    width: 100%;
    margin-bottom: 20px;
  }

  .field {
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }

  .passwordFooter {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
  }

  .passwordFooter a {
    margin-right: 12px;
    width: 50%;
  }
  .passwordFooter a button {
    width: 100%;
  }
  .passwordFooter button {
    width: 50%;
  }

  .stepTwo {
    width: 100%;
  }
  .smallHeader {
    display: flex;
  }

  .wrapper {
    flex-direction: column;
  }

  .side {
    min-height: 0;
  }
  .side.leftSide {
    display: flex;
    width: 100%;
    position: relative;
    min-height: 0;
  }

  .side.leftSide .wizard {
    margin: 0;
    padding: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .b {
    display: none;
  }

  .side.leftSide .wizard .step {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 33.3%;
  }

  .stepPoint {
    margin-bottom: 0;
  }

  .logo {
    display: none;
  }

  .side.leftSide .wizard .step:not(:last-child):after {
    width: calc(50% - 15px);
    height: 2px;
    background: var(--teal-400);
    top: 15px;
    right: auto;
    left: calc(50% + 15px);
    margin: 0;
  }

  .side.leftSide .wizard .step:not(:first-child):before {
    width: calc(50% - 15px);
    height: 2px;
    background: var(--teal-400);
    top: 15px;
    left: auto;
    right: calc(50% + 30px);
    margin: 0;
    display: inline-block;
    content: "";
    position: absolute;
  }
  .mobileLogo {
    display: block;
    left: 32px;
    top: 32px;
    transform: none;
  }

  .stepText {
    display: none;
  }

  .year {
    display: none;
  }
  .hint {
    display: none;
  }

  .side.rightSide {
    width: 100%;
    position: relative;
    flex: 1;
    padding: 24px 32px 0 32px;
  }

  .pageFooter {
    display: block;
  }

  .rightSideContent {
    max-width: 100%;
    flex: 1;
  }

  .spinner {
    left: 0;
    top: 0;
    width: calc(100%) !important;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .hint {
    display: none;
  }
  .wrapper {
    flex-direction: column;
  }
  .verifySubtitle {
    font-size: 20px !important;
  }

  .mobilePasswordCheck {
    display: block;
  }

  .side {
    min-height: 0;
  }
  .side.leftSide {
    display: flex;
    width: 100%;
    position: relative;
    min-height: 0;
  }

  .side.leftSide .wizard {
    margin: 0;
    padding: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .b {
    display: none;
  }

  .side.leftSide .wizard .step {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 33.3%;
  }

  .stepPoint {
    margin-bottom: 24px;
  }

  .logo {
    display: none;
  }

  .side.leftSide .wizard .step:not(:last-child):after {
    width: calc(50% - 15px);
    height: 2px;
    background: var(--teal-400);
    top: 15px;
    right: auto;
    left: calc(50% + 15px);
    margin: 0;
  }

  .side.leftSide .wizard .step:not(:first-child):before {
    width: calc(50% - 15px);
    height: 2px;
    background: var(--teal-400);
    top: 15px;
    left: auto;
    right: calc(50% + 30px);
    margin: 0;
    display: inline-block;
    content: "";
    position: absolute;
  }

  .mobileLogo {
    display: block;
    left: 32px;
    top: 32px;
    transform: none;
  }

  .year {
    display: none;
  }

  .side.rightSide {
    width: 100%;
    position: relative;
    padding: 24px 32px 0 32px;
    flex: 1;
  }

  .pageFooter {
    display: block;
  }

  .smallHeader {
    display: flex;
  }

  .rightSideContent {
    max-width: 100%;
    flex: 1;
  }

  .spinner {
    left: 0;
    top: 0;
    width: calc(100%) !important;
  }
}

.heardoflist {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1em;
  flex-wrap: wrap;
}

.heardoflist li {
  border: 1px solid black;
  border-radius: 25px;
  padding: 0.5em 1em;
  cursor: pointer;
}
.active {
  background-color: var(--cool-600);
  color: white;
}
.heardoflist li:hover {
  background-color: var(--cool-600);
  color: white;
}

.continueBtn {
  margin-top: 2rem;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}

.senjaContainer {
  padding-top: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
