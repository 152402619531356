@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --br: 8px;
  --input-paddings: 8px 12px;
  --container: 1280px;
  --container-paddings: 32px;
  --textarea-paddings: 12px;
  --datepicker-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  --dropdown-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  --field-mb: 16px;
  --field-xs-mb: 8px;
  --dropdown-li-padding: 10px 8px;
  --textarea-min-height: 128px;
  --input-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --input-focus-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px #ebf0ff;
  --input-error-focus-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px #fee4e2;
  --animation: ease-in-out 0.2s;
  --font: "Inter", sans-serif;
  --header: 72px;
  --budget-bar-small: 52px;
  --budget-bar-big: 128px;
  --budget-bar-big-message: 172px;
  --sidebar: 448px;
  --sidebar-small: 82px;
  --line: 10px;

  --black: #000000;
  --snow: #ffffff;

  --cool-600: #0e2952;

  --gray-blue-500: #4e5ba6;

  --bg: #fcfcfd;

  --gray-25: #fcfcfd;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-900: #101828;
  --gray-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --gray-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;

  --primary-25: #f9fbff;
  --primary-50: #f5f7ff;
  --primary-100: #ebf0ff;
  --primary-200: #d6e1ff;
  --primary-300: #9fa9ba;
  --primary-500: #6e7f97;
  --primary-600: #566986;
  --primary-700: #3e5475;
  --primary-800: #263e63;
  --primary-900: #0e2952;
  --primary-1000: #101828;

  --teal-50: #f0fdf9;
  --teal-100: #ccfbef;
  --teal-200: #b9efea;
  --teal-300: #73ded6;
  --teal-400: #30d5c8;
  --teal-500: #14b4a7;
  --teal-600: #0f8c82;
  --teal-700: #107569;
  --teal-800: #125d56;
  --teal-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --teal-medium-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px #b9efea;
  --teal-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ccfbef;

  --red-200: #fecdca;
  --red-300: #fda29b;
  --red-500: #f04438;
  --red-600: #d92d20;
  --red-700: #b42318;
  --red-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --red-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee4e2;

  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-300: #fda29b;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;

  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-300: #fec84b;
  --warning-500: #f79009;
  --warning-700: #b54708;
  --warning-600: #dc6803;

  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
}

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--container);
  box-sizing: border-box;
  width: 100%;
  padding-left: var(--container-paddings);
  padding-right: var(--container-paddings);
  padding-bottom: var(--container-paddings);
}

.container.wb {
  padding: 0;
}

.auth .intercom-lightweight-app-launcher {
  display: none;
}

a {
  color: var(--teal-400);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font);
  text-decoration: none;
}

.pureList {
  padding-left: 10px;
  list-style: none;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 12px !important;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border: 1px solid var(--gray-100) !important;
  padding: 12px !important;
  font-family: var(--font) !important;
}
.Toastify__toast-container {
  width: auto !important;
}

@media all and (max-width: 1199px) {
  body {
    --container: 768px;
    --container-paddings: 16px;
  }
}

.block-scroll {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content--after-open {
  animation: scale-up-center 0.2s;
}

.ReactModal__Content--before-close {
  animation: scale-down-center 0.2s;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: auto !important;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell-comp-wrapper {
  justify-content: center;
}
