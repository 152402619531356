.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  padding-top: 48px;
}

.center {
  align-items: center;
  justify-content: center;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card {
  width: calc(25% - 12px);
  max-width: 210px;
  flex: 1;
  height: 130px;
}

.card:not(:last-child) {
  margin-right: 24px;
}

.cardTitle {
  margin: 20px 0 4px;
  color: var(--gray-900);
  line-height: 18px;
}

.cardValue {
  white-space: nowrap;
  flex: 1 100%;
  display: flex;
  align-items: flex-end;
  color: var(--gray-900);
}

.chartWrapper {
  flex-direction: row;
  align-items: flex-start;
  max-height: 650px;
  flex: 1 100%;
}

.bars {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-items: baseline;
}

.bar {
  width: calc(100% / 6);
  display: flex;
  flex-direction: column-reverse;
}

.cell {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-top: 1px solid var(--gray-200);
}

.dot {
  background: linear-gradient(137.64deg, #30d5c8 18.65%, #1ca499 86.51%);
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: width var(--animation), background var(--animation),
    height var(--animation);
  border-radius: 100%;
}

.dot.blue {
  width: 40px;
  height: 40px;
  background: linear-gradient(137.64deg, #7bbfe0 18.65%, #507ba5 86.51%);
}

.blueDot {
  background: var(--primary-600);
  border-radius: 20px;
  padding: 4px 8px;
  color: var(--snow);
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.bar:nth-child(odd) .cell {
  background: rgba(214, 225, 255, 0.3);
}

.barTitle {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-200);
  justify-content: center;
  padding: 12px 8px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}

.chart {
  /* width: 70%; */
  box-sizing: border-box;
}

.infoWrapper {
  width: 30%;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.progress {
  margin: 6px 0 18px;
}

.infoTitle {
  margin-bottom: 12px;
}

.infoField {
  margin-bottom: 12px;
  color: var(--gray-600);
}

.capitalizedField {
  text-transform: capitalize;
}
