.tabTitle {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.bmTable {
  width: 100%;
  border-spacing: 0;
  color: #475467;
}

.bmTable thead {
  background-color: #f9fafb;
}
.bmTable td {
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  border-collapse: collapse;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
}

.paginationButton {
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 6px 8px;

  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--snow);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #475467;
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-wrap: nowrap;

  cursor: pointer;
}
.paginationButton:disabled,
.paginationButton:hover {
  background-color: var(--gray-200);
}

.paginationButtonIcon {
  height: 14px;
  width: 14px;
}

.inlineEditNoEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconButton {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(225, 100%, 96%);
  border-radius: 100%;
  transition: all 100ms;
  cursor: pointer;
}
.iconButton:hover {
  background-color: hsl(225, 100%, 90%);
  scale: 1.1;
}
.iconButton > svg {
  height: 12px;
  width: 12px;
}

.inlineEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inlineEditInput {
  border: none;
  margin-right: 4px;
  width: 20px;
  font-size: 16px;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 3px;
}
