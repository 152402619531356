.dropdown {
  padding: 7px 1em;
  display: grid;
  grid-template-columns: 16px 1fr 14px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid var(--gray-300);
  background: var(--snow);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #475467;
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-wrap: nowrap;

  cursor: pointer;

  margin-right: 1em;
}
.dropdown span {
  padding: 0 1em;
}
.dropdown:hover {
  background-color: var(--gray-200);
}

.list {
  position: absolute;
  top: 100%;
  min-width: 150px; /*???*/
  left: 0;
  max-height: 320px;
  overflow-y: auto;
  box-shadow: var(--dropdown-shadow);
  margin-top: 4px;
  background: var(--snow);
  list-style: none;
  padding: 0;
  z-index: 3;
  box-sizing: border-box;
  border: 1px solid var(--gray-200);
  border-radius: var(--br);
}

.listItem {
}

.listItemContent {
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  gap: 0.5em;
  padding: 15px 18px;
  align-items: center;
  white-space: nowrap;
  line-height: 24px;
  color: var(--gray-700);
  border-radius: var(--br);
  cursor: pointer;
}
.listItemContent:hover {
  background-color: var(--gray-50);
}

.icon {
  height: 16px;
  width: 16px;
}
