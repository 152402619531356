.pageCenter {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
}
.header img {
  height: 58px;
}

.tokenIcon {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  height: 12px;
}

.claimButton {
  display: block;
  background-color: #30d5c8;
  color: white;
  border-radius: 8px;
  width: 25em;
  padding: 1em 2em;
  border: none;
  margin: 1em auto;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.claimButton:hover {
  background-color: hsl(175, 66%, 40%);
}

.claimText {
  text-align: center;
  color: #475467;
  padding-top: 2em;
  padding-bottom: 2em;
}
