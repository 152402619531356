.product {
  display: flex;
  gap: 1em;
  justify-content: space-between;
}

.subtitle {
  color: var(--gray-600);
}

.button {
  border-radius: 8px;
  border: 0 solid var(--gray-300);
  background: linear-gradient(90deg, #30d5c8 0.06%, #2b619b 99.92%);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 10px 16px;
  width: auto;
  color: var(--snow);
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  align-items: center;
}

.button:hover {
  background-size: 140% 100%;
  cursor: pointer;
  animation: gradient 1s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
