.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 6px;
}

.tabTitle {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.btn {
  border-radius: 8px;
  border: 0 solid var(--gray-300);
  background: linear-gradient(90deg, #30d5c8 0.06%, #2b619b 99.92%);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 10px 16px;
  color: var(--snow);
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  align-items: center;
}

.btn:hover {
  background-size: 140% 100%;
  animation: gradient 1s ease infinite;
}

.incrementNumberInput {
  display: flex;
}
.inputField {
  width: 6em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.buttonMinus {
  font-weight: 400;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  background: var(--snow);
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
  border-left: none;
  border-right: none;
  height: 42px;
  width: 42px;
  color: rgb(30, 192, 179);
  cursor: pointer;
}
.buttonPlus {
  font-weight: 400;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  background: var(--snow);
  border: 1px solid var(--gray-300);
  border-top-right-radius: var(--br);
  border-bottom-right-radius: var(--br);
  height: 42px;
  width: 42px;
  color: rgb(30, 192, 179);
  cursor: pointer;
}

.buttonPlus:hover {
  background: linear-gradient(90deg, #30d5c8 0.06%, #2b619b 99.92%);
  background-size: 140% 100%;
  animation: gradient 1s ease infinite;
  color: white;
}
.buttonMinus:hover {
  background: linear-gradient(90deg, #30d5c8 0.06%, #2b619b 99.92%);
  background-size: 140% 100%;
  animation: gradient 1s ease infinite;
  color: white;
}

.icons {
  height: 48px;
  object-fit: contain;
  align-self: flex-start;
  margin-bottom: 0.5rem;
}

.tokenShareTitle {
  margin-bottom: 0.5rem;
}

.tokenShare {
  display: flex;
  align-items: center;
}

.budgetMentorIcon {
  height: 20px;
  object-fit: contain;
  margin-left: 1em;
  margin-right: 0.5em;
}

.infiniteToken {
  margin-top: 0.25rem;
}

.horizontalLine {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray-300);
  position: relative;
}
.horizontalLineOr {
  position: absolute;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.sendEmail {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  width: 100%;
}

.sendButton {
  display: flex;
  align-items: center;
  color: rgb(30, 192, 179);
  font-size: 16px;
  font-weight: bold;
  background: var(--snow);
  border: 1px solid var(--gray-300);
  border-top-right-radius: var(--br);
  border-bottom-right-radius: var(--br);
  border-left: none;
  gap: 0.25rem;
  height: 42px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.sendButton:hover {
  background-color: var(--gray-200);
}

.emailBadge {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #30d5c8;
  color: white;
  padding: 0.25em 1em;
  border-radius: 5000px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.emailBadgeClose {
  height: 16px;
  width: 16px;
  border-radius: 5000px;
  cursor: pointer;
}
.emailBadgeClose:hover {
  background-color: hsl(175, 66%, 41%);
}
