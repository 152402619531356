.editableCell {
  background-color: var(--teal-400);
}

.groupCell {
  background-color: #cdcdcd;
  text-align: center;
}

.smallerHeaderText {
  font-size: 10px;
}

.right {
  text-align: left;
}

.footer {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  padding-bottom: 40px;
}

.footerCell {
  background-color: #14b4a7;
  color: #ffffff;
  text-align: center;
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.yearHeader {
  text-align: center;
  padding: 4px;
}

.fromToContainer {
  display: flex;
  gap: 5px;
  font-size: 10px;
  padding: 4px;
}

.center {
  text-align: center;
}

.link {
  color: var(--teal-500, #14b4a7);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}
